/*! Kanban component's material theme definitions and variables */
/*! kanban component layout */
.e-kanban {
  display: block;
  outline: medium none;
  position: relative;
  user-select: none;
}
.e-kanban .e-kanban-table {
  border: 0;
  border-collapse: separate;
  border-spacing: 10px 0;
  table-layout: fixed;
  width: 100%;
}
.e-kanban .e-kanban-table col.e-collapsed {
  width: 50px;
}
.e-kanban .e-swimlane .e-kanban-table.e-content-table {
  border-spacing: 10px 0;
}
.e-kanban .e-frozen-swimlane-row {
  position: relative;
  z-index: 101;
}
.e-kanban .e-frozen-swimlane-row .e-frozen-row {
  padding: 13px 0 0 10px;
}
.e-kanban .e-frozen-swimlane-row .e-frozen-row .e-swimlane-header {
  display: flex;
}
.e-kanban
  .e-frozen-swimlane-row
  .e-frozen-row
  .e-swimlane-header
  .e-swimlane-text {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  padding: 2px;
}
.e-kanban
  .e-frozen-swimlane-row
  .e-frozen-row
  .e-swimlane-header
  .e-item-count {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  opacity: 0.57;
  padding: 2px;
}
.e-kanban
  .e-frozen-swimlane-row
  .e-frozen-row
  .e-swimlane-header
  .e-swimlane-row-expand,
.e-kanban
  .e-frozen-swimlane-row
  .e-frozen-row
  .e-swimlane-header
  .e-swimlane-row-collapse {
  border: 1px solid transparent;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  font-size: 12px;
  height: 24px;
  margin: 0 0 0 8px;
  padding: 6px;
}
.e-kanban
  .e-frozen-swimlane-row
  .e-frozen-row
  .e-swimlane-header
  .e-swimlane-row-expand:hover,
.e-kanban
  .e-frozen-swimlane-row
  .e-frozen-row
  .e-swimlane-header
  .e-swimlane-row-expand:focus,
.e-kanban
  .e-frozen-swimlane-row
  .e-frozen-row
  .e-swimlane-header
  .e-swimlane-row-collapse:hover,
.e-kanban
  .e-frozen-swimlane-row
  .e-frozen-row
  .e-swimlane-header
  .e-swimlane-row-collapse:focus {
  background: #e0e0e0;
  border: 1px solid transparent;
  border-radius: 100%;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.54);
  margin: 0 0 0 8px;
  padding: 6px;
}
.e-kanban
  .e-frozen-swimlane-row
  .e-frozen-row
  .e-swimlane-header
  .e-swimlane-row-expand:focus,
.e-kanban
  .e-frozen-swimlane-row
  .e-frozen-row
  .e-swimlane-header
  .e-swimlane-row-collapse:focus {
  background: #e0e0e0;
  box-shadow: none;
}
.e-kanban .e-kanban-header > div {
  overflow: hidden;
}
.e-kanban .e-kanban-header .e-header-cells {
  border-radius: 4px;
  height: 50px;
  overflow: hidden;
  padding: 5px 8px;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.e-kanban .e-kanban-header .e-header-cells .e-header-wrap {
  display: flex;
}
.e-kanban .e-kanban-header .e-header-cells .e-header-wrap .e-header-title {
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.e-kanban .e-kanban-header .e-header-cells.e-stacked-header-cell {
  border-bottom: 1px solid #9e9e9e;
}
.e-kanban .e-kanban-header .e-header-cells.e-toggle-header .e-column-expand {
  cursor: pointer;
  font-size: 12px;
  padding: 6px;
}
.e-kanban .e-kanban-header .e-header-cells.e-toggle-header .e-column-collapse {
  cursor: pointer;
  font-size: 12px;
  padding: 11px;
}
.e-kanban .e-kanban-header .e-header-cells.e-min-color {
  background: #ffe082;
}
.e-kanban .e-kanban-header .e-header-cells.e-max-color {
  background: #ffcdd2;
}
.e-kanban .e-kanban-header .e-header-cells.e-collapsed {
  background: #f5f5f5;
  text-align: center;
  width: 50px;
}
.e-kanban .e-kanban-header .e-header-cells.e-collapsed .e-header-wrap {
  justify-content: center;
}
.e-kanban .e-kanban-header .e-header-cells.e-collapsed.e-min-color {
  background: #ffe082;
}
.e-kanban .e-kanban-header .e-header-cells.e-collapsed.e-max-color {
  background: #ffcdd2;
}
.e-kanban .e-kanban-header .e-header-cells.e-collapsed .e-limits,
.e-kanban .e-kanban-header .e-header-cells.e-collapsed .e-header-title {
  display: none;
}
.e-kanban .e-kanban-header .e-header-cells .e-header-text {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  overflow: hidden;
  padding-right: 5px;
  text-overflow: ellipsis;
}
.e-kanban .e-kanban-header .e-header-cells .e-item-count {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 0;
  opacity: 0.57;
  text-transform: lowercase;
}
.e-kanban .e-kanban-header .e-header-cells .e-limits {
  display: flex;
  padding-top: 3px;
}
.e-kanban .e-kanban-header .e-header-cells .e-limits .e-min-count,
.e-kanban .e-kanban-header .e-header-cells .e-limits .e-max-count {
  font-size: 13px;
  font-weight: 400;
  opacity: 0.57;
  width: 100%;
}
.e-kanban .e-kanban-header .e-header-cells .e-limits .e-max-count {
  text-align: right;
}
.e-kanban .e-kanban-content {
  overflow: auto;
  padding-bottom: 10px;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-limits {
  display: flex;
  height: 26px;
  position: relative;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-kanban-border {
  display: none;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-kanban-border.e-dropping {
  display: block;
  position: absolute;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-limits
  .e-min-count,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-limits
  .e-max-count {
  font-size: 13px;
  font-weight: 400;
  opacity: 0.57;
  padding: 4px 8px;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-limits
  .e-max-count {
  position: absolute;
  right: 0;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells.e-min-color {
  background: #ffe082;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells.e-max-color {
  background: #ffcdd2;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells.e-collapsed {
  overflow: hidden;
  text-align: center;
  width: 50px;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells.e-collapsed
  .e-card-wrapper,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells.e-collapsed
  .e-card-container,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells.e-collapsed
  .e-limits {
  display: none;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells.e-collapsed
  .e-collapse-header-text {
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 400;
  overflow: hidden;
  padding: 8px 0 0 13px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  writing-mode: vertical-lr;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells.e-collapsed
  .e-collapse-header-text
  .e-item-count {
  display: inline-block;
  font-size: 13px;
  font-weight: normal;
  opacity: 0.57;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-show-add-button {
  border: 1px dashed rgb(95, 124, 138);
  height: 36px;
  margin: 8px 8px 0;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-show-add-button
  .e-show-add-icon {
  padding: 11px;
  text-align: center;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-show-add-button:focus {
  border: 1px dashed #9e9e9e;
  color: #9e9e9e;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-multi-card-wrapper,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-multi-card-container {
  display: none;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container {
  height: inherit;
  overflow: auto;
  padding: 8px 8px 0;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-empty-card,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-empty-card {
  color: inherit;
  display: inline;
  font-size: 13px;
  line-height: 40px;
  margin: 0;
  padding: 10px 0;
  position: relative;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card {
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: none;
  margin-bottom: 8px;
  min-height: 50px;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card.e-draggable,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card.e-draggable {
  cursor: all-scroll;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card
  .e-card-header,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card
  .e-card-header {
  padding: 7px 11px 0;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card
  .e-card-header
  .e-card-header-title,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card
  .e-card-header
  .e-card-header-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card
  .e-card-footer,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card
  .e-card-footer {
  display: inline-flex;
  line-height: normal;
  padding: 0 11px 7px;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card
  .e-card-content,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card
  .e-card-content {
  font-size: 13px;
  line-height: 20px;
  padding: 8px 11px;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card.e-card-color,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card.e-card-color {
  border: 1px solid transparent;
  border-left: 3px solid;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card.e-card-color
  .e-card-header,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card.e-card-color
  .e-card-header {
  padding: 7px 11px 0 8px;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card.e-card-color
  .e-card-content,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card.e-card-color
  .e-card-content {
  padding: 8px 11px 8px 8px;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card.e-card-color
  .e-card-tags,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card.e-card-color
  .e-card-tags {
  padding: 0 11px 8px 8px;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card.e-card-color.e-selection,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card.e-card-color.e-selection {
  border: 1px solid transparent;
  border-left: 3px solid;
  box-shadow: none;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card.e-card-color.e-selection:hover,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card.e-card-color.e-selection:hover {
  border: 1px solid transparent;
  border-left: 3px solid;
  box-shadow: none;
  padding: 0;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card.e-card-color:hover,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card.e-card-color:hover {
  border: 1px solid transparent;
  border-left: 3px solid;
  box-shadow: none;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card.e-card-color
  .e-card-footer,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card.e-card-color
  .e-card-footer {
  padding: 0 11px 7px 8px;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card
  .e-card-tag,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card
  .e-card-tag {
  border-radius: 12px;
  display: inline-block;
  font-size: 12px;
  line-height: 20px;
  margin-right: 8px;
  max-width: 100%;
  overflow: hidden;
  padding: 2px 8px;
  text-overflow: ellipsis;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card
  .e-card-tags,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card
  .e-card-tags {
  line-height: 1;
  padding: 0 11px 8px;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card.e-cloned-card,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card.e-cloned-card {
  position: absolute;
  z-index: 1;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card.e-selection,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card.e-selection {
  border: 1px solid transparent;
  box-shadow: none;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card.e-selection:hover,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card.e-selection:hover {
  border: 1px solid transparent;
  box-shadow: none;
  padding: 0;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card:hover,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card:hover {
  border: 1px solid transparent;
  box-shadow: none;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card:focus,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card:focus {
  border: 1px solid #e0e0e0;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card.e-card-color:focus,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card.e-card-color:focus {
  border: 1px solid transparent;
  border-left: 3px solid;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card.e-kanban-dragged-card,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card.e-kanban-dragged-card {
  display: none;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-multi-card-clone,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-multi-card-clone {
  border: 1px solid #9e9e9e;
  height: 34px;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-multi-card-text,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-multi-card-text {
  background: #fff;
  display: table-cell;
  padding-left: 8px;
  text-align: center;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-target-dragged-clone,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-target-dropped-clone,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-target-dragged-clone,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-target-dropped-clone {
  border-radius: 0;
  margin-bottom: 8px;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-target-dragged-clone,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-target-dragged-clone {
  background: #f5f5f5;
  border: 1px dashed #9e9e9e;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-target-dropped-clone,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-target-dropped-clone {
  background: rgba(95, 124, 138, 0.1);
  border: 1px dashed rgb(95, 124, 138);
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-target-multi-clone
  .e-column-key {
  border-bottom: 1px dashed #9e9e9e;
  border-left: 1px dashed #9e9e9e;
  border-right: 1px dashed #9e9e9e;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-target-multi-clone
  .e-column-key
  .e-text {
  opacity: 0.5;
  text-align: center;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-target-multi-clone
  .e-column-key:first-child {
  border-top: 1px dashed #9e9e9e;
}
.e-kanban .e-kanban-content .e-content-row.e-swimlane-row .e-content-cells {
  height: 50px;
  min-height: 50px;
}
.e-kanban
  .e-kanban-content
  .e-content-row.e-swimlane-row
  .e-content-cells
  .e-swimlane-header {
  display: flex;
  padding: 0;
}
.e-kanban
  .e-kanban-content
  .e-content-row.e-swimlane-row
  .e-content-cells
  .e-swimlane-header
  .e-swimlane-row-expand,
.e-kanban
  .e-kanban-content
  .e-content-row.e-swimlane-row
  .e-content-cells
  .e-swimlane-header
  .e-swimlane-row-collapse {
  border: 1px solid transparent;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  font-size: 12px;
  height: 24px;
  margin: 0 0 0 8px;
  padding: 6px;
}
.e-kanban
  .e-kanban-content
  .e-content-row.e-swimlane-row
  .e-content-cells
  .e-swimlane-header
  .e-swimlane-row-expand:hover,
.e-kanban
  .e-kanban-content
  .e-content-row.e-swimlane-row
  .e-content-cells
  .e-swimlane-header
  .e-swimlane-row-expand:focus,
.e-kanban
  .e-kanban-content
  .e-content-row.e-swimlane-row
  .e-content-cells
  .e-swimlane-header
  .e-swimlane-row-collapse:hover,
.e-kanban
  .e-kanban-content
  .e-content-row.e-swimlane-row
  .e-content-cells
  .e-swimlane-header
  .e-swimlane-row-collapse:focus {
  background: #e0e0e0;
  border: 1px solid transparent;
  border-radius: 100%;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  margin: 0 0 0 8px;
  padding: 6px;
}
.e-kanban
  .e-kanban-content
  .e-content-row.e-swimlane-row
  .e-content-cells
  .e-swimlane-header
  .e-swimlane-text {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  padding: 2px;
}
.e-kanban
  .e-kanban-content
  .e-content-row.e-swimlane-row
  .e-content-cells
  .e-swimlane-header
  .e-item-count {
  color: inherit;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  opacity: 0.57;
  padding: 2px;
}
.e-kanban
  .e-kanban-content
  .e-content-row:not(.e-swimlane-row)
  .e-content-cells {
  border: 1px solid transparent;
  border-radius: 4px;
  vertical-align: top;
}
.e-kanban
  .e-kanban-content
  .e-content-row:not(.e-swimlane-row)
  .e-content-cells.e-dropping {
  border: 1px dashed #9e9e9e;
}
.e-kanban
  .e-kanban-content
  .e-content-row:not(.e-swimlane-row)
  .e-content-cells.e-min-color {
  background: #ffe082;
}
.e-kanban
  .e-kanban-content
  .e-content-row:not(.e-swimlane-row)
  .e-content-cells.e-max-color {
  background: #ffcdd2;
}
.e-kanban
  .e-kanban-content
  .e-content-row:not(.e-swimlane-row)
  .e-content-cells:focus {
  border: 1px solid #e0e0e0;
  box-shadow: none;
}
.e-kanban
  .e-kanban-content
  .e-content-row:not(.e-swimlane-row)
  .e-content-cells
  .e-card-wrapper,
.e-kanban
  .e-kanban-content
  .e-content-row:not(.e-swimlane-row)
  .e-content-cells
  .e-card-container {
  min-height: 50px;
}
.e-kanban
  .e-kanban-content
  .e-content-row:not(.e-swimlane-row)
  .e-content-cells
  .e-dropping {
  border: 1px dashed #9e9e9e;
}
.e-kanban
  .e-kanban-content
  .e-content-row.e-collapsed:not(.e-swimlane-row)
  .e-content-cells {
  border-bottom: 0;
  height: 0;
}
.e-kanban
  .e-kanban-content
  .e-content-row.e-collapsed:not(.e-swimlane-row)
  .e-content-cells
  > .e-collapse-header-text,
.e-kanban
  .e-kanban-content
  .e-content-row.e-collapsed:not(.e-swimlane-row)
  .e-content-cells
  .e-limits,
.e-kanban
  .e-kanban-content
  .e-content-row.e-collapsed:not(.e-swimlane-row)
  .e-content-cells
  .e-show-add-button,
.e-kanban
  .e-kanban-content
  .e-content-row.e-collapsed:not(.e-swimlane-row)
  .e-content-cells
  .e-card-wrapper,
.e-kanban
  .e-kanban-content
  .e-content-row.e-collapsed:not(.e-swimlane-row)
  .e-content-cells
  .e-card-container {
  display: none;
}
.e-kanban.e-rtl .e-kanban-table .e-header-cells {
  text-align: right;
}
.e-kanban.e-rtl .e-kanban-table .e-header-cells .e-header-text {
  padding-left: 5px;
  padding-right: initial;
  text-align: right;
  text-transform: uppercase;
}
.e-kanban.e-rtl .e-kanban-table .e-header-cells .e-limits .e-max-count {
  text-align: left;
}
.e-kanban.e-rtl
  .e-kanban-table
  .e-header-cells.e-toggle-header
  .e-column-collapse {
  text-align: center;
}
.e-kanban.e-rtl
  .e-kanban-table.e-content-table
  .e-content-row
  .e-content-cells
  .e-limits
  .e-max-count {
  left: 0;
  right: auto;
}
.e-kanban.e-rtl
  .e-kanban-table.e-content-table
  .e-content-row
  .e-content-cells.e-collapsed
  .e-collapse-header-text {
  direction: initial;
}
.e-kanban.e-rtl
  .e-kanban-table.e-content-table
  .e-content-row.e-swimlane-row
  .e-content-cells
  .e-swimlane-header
  .e-swimlane-row-expand,
.e-kanban.e-rtl
  .e-kanban-table.e-content-table
  .e-content-row.e-swimlane-row
  .e-content-cells
  .e-swimlane-header
  .e-swimlane-row-collapse {
  margin: 0 8px 0 0;
}
.e-kanban.e-device .e-swimlane-header .e-swimlane-header-toolbar {
  background: #f5f5f5;
  border-bottom: 0;
  display: flex;
  margin-bottom: 3px;
  min-height: 42px;
  padding: 10px;
}
.e-kanban.e-device
  .e-swimlane-header
  .e-swimlane-header-toolbar
  .e-toolbar-swimlane-name {
  color: inherit;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  padding: 5px;
}
.e-kanban.e-device
  .e-swimlane-header
  .e-swimlane-header-toolbar
  .e-toolbar-menu,
.e-kanban.e-device
  .e-swimlane-header
  .e-swimlane-header-toolbar
  .e-toolbar-level-title {
  padding-top: 6px;
}
.e-kanban.e-device
  .e-swimlane-header
  .e-swimlane-header-toolbar
  .e-toolbar-menu
  .e-icon-menu,
.e-kanban.e-device
  .e-swimlane-header
  .e-swimlane-header-toolbar
  .e-toolbar-level-title
  .e-icon-menu {
  color: inherit;
  font-size: 14px;
  padding: 7px;
  padding-left: 4px;
}
.e-kanban.e-device .e-kanban-header .e-swimlane .e-header-cells {
  border-bottom: 0;
}
.e-kanban.e-device .e-swimlane-content {
  position: absolute;
  width: 100%;
}
.e-kanban.e-device .e-swimlane-content .e-swimlane-resource {
  background: #fff;
  border: 1px solid #e0e0e0;
  height: 100%;
  width: 225px;
}
.e-kanban.e-device .e-swimlane-content .e-swimlane-resource .e-swimlane-tree {
  height: 100%;
  overflow-y: auto;
}
.e-kanban.e-device .e-swimlane-content .e-swimlane-overlay {
  position: absolute;
}
.e-kanban.e-device .e-swimlane-content .e-swimlane-overlay.e-enable {
  background: #383838;
  height: 100%;
  opacity: 0.5;
  width: 100%;
}

.e-bigger .e-kanban .e-swimlane-header-toolbar,
.e-bigger.e-kanban .e-swimlane-header-toolbar {
  margin-bottom: 5px;
  min-height: 56px;
}
.e-bigger .e-kanban .e-swimlane-header-toolbar .e-toolbar-swimlane-name,
.e-bigger.e-kanban .e-swimlane-header-toolbar .e-toolbar-swimlane-name {
  font-size: 18px;
}
.e-bigger .e-kanban .e-swimlane-header-toolbar .e-icon-menu,
.e-bigger.e-kanban .e-swimlane-header-toolbar .e-icon-menu {
  font-size: 18px;
}
.e-bigger .e-kanban .e-kanban-table.e-content-table,
.e-bigger.e-kanban .e-kanban-table.e-content-table {
  border-spacing: 10px 0;
}
.e-bigger .e-kanban .e-swimlane .e-kanban-table.e-content-table,
.e-bigger.e-kanban .e-swimlane .e-kanban-table.e-content-table {
  border-spacing: 10px 0;
}
.e-bigger .e-kanban .e-frozen-swimlane-row .e-frozen-row,
.e-bigger.e-kanban .e-frozen-swimlane-row .e-frozen-row {
  padding: 13px 0 0 10px;
}
.e-bigger
  .e-kanban
  .e-frozen-swimlane-row
  .e-frozen-row
  .e-swimlane-header
  .e-swimlane-text,
.e-bigger.e-kanban
  .e-frozen-swimlane-row
  .e-frozen-row
  .e-swimlane-header
  .e-swimlane-text {
  font-size: 14px;
}
.e-bigger
  .e-kanban
  .e-frozen-swimlane-row
  .e-frozen-row
  .e-swimlane-header
  .e-item-count,
.e-bigger.e-kanban
  .e-frozen-swimlane-row
  .e-frozen-row
  .e-swimlane-header
  .e-item-count {
  line-height: 20px;
  padding: 2px;
}
.e-bigger .e-kanban .e-kanban-table col.e-collapsed,
.e-bigger.e-kanban .e-kanban-table col.e-collapsed {
  width: 50px;
}
.e-bigger .e-kanban .e-kanban-table .e-header-cells,
.e-bigger.e-kanban .e-kanban-table .e-header-cells {
  height: 56px;
}
.e-bigger .e-kanban .e-kanban-table .e-header-cells .e-header-text,
.e-bigger.e-kanban .e-kanban-table .e-header-cells .e-header-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-transform: lowercase;
}
.e-bigger .e-kanban .e-kanban-table .e-header-cells .e-item-count,
.e-bigger.e-kanban .e-kanban-table .e-header-cells .e-item-count {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 0;
  text-transform: lowercase;
}
.e-bigger .e-kanban .e-kanban-table .e-header-cells .e-column-collapse,
.e-bigger .e-kanban .e-kanban-table .e-header-cells .e-column-expand,
.e-bigger.e-kanban .e-kanban-table .e-header-cells .e-column-collapse,
.e-bigger.e-kanban .e-kanban-table .e-header-cells .e-column-expand {
  font-size: 12px;
}
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper,
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container {
  padding: 8px 8px 0;
}
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-target-dragged-clone,
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-target-dropped-clone,
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-target-dragged-clone,
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-target-dropped-clone,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-target-dragged-clone,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-target-dropped-clone,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-target-dragged-clone,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-target-dropped-clone {
  margin-bottom: 8px;
}
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-swimlane-row
  .e-content-cells
  .e-swimlane-header,
.e-bigger.e-kanban
  .e-kanban-content
  .e-swimlane-row
  .e-content-cells
  .e-swimlane-header {
  padding: 0;
}
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-swimlane-row
  .e-content-cells
  .e-swimlane-header
  .e-swimlane-text,
.e-bigger.e-kanban
  .e-kanban-content
  .e-swimlane-row
  .e-content-cells
  .e-swimlane-header
  .e-swimlane-text {
  font-size: 14px;
  line-height: 20px;
}
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-swimlane-row
  .e-content-cells
  .e-swimlane-header
  .e-item-count,
.e-bigger.e-kanban
  .e-kanban-content
  .e-swimlane-row
  .e-content-cells
  .e-swimlane-header
  .e-item-count {
  font-size: 12px;
  line-height: 20px;
  padding: 2px;
}
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-swimlane-row
  .e-content-cells
  .e-swimlane-header
  .e-swimlane-row-expand,
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-swimlane-row
  .e-content-cells
  .e-swimlane-header
  .e-swimlane-row-collapse,
.e-bigger.e-kanban
  .e-kanban-content
  .e-swimlane-row
  .e-content-cells
  .e-swimlane-header
  .e-swimlane-row-expand,
.e-bigger.e-kanban
  .e-kanban-content
  .e-swimlane-row
  .e-content-cells
  .e-swimlane-header
  .e-swimlane-row-collapse {
  font-size: 12px;
}
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card.e-card-color
  .e-card-header,
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card.e-card-color
  .e-card-header,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card.e-card-color
  .e-card-header,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card.e-card-color
  .e-card-header {
  padding: 7px 11px 0 8px;
}
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card.e-card-color
  .e-card-content,
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card.e-card-color
  .e-card-content,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card.e-card-color
  .e-card-content,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card.e-card-color
  .e-card-content {
  padding: 8px 11px 8px 8px;
}
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card.e-card-color
  .e-card-tags,
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card.e-card-color
  .e-card-footer,
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card.e-card-color
  .e-card-tags,
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card.e-card-color
  .e-card-footer,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card.e-card-color
  .e-card-tags,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card.e-card-color
  .e-card-footer,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card.e-card-color
  .e-card-tags,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card.e-card-color
  .e-card-footer {
  padding: 0 11px 7px 8px;
}
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card
  .e-card-header,
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card
  .e-card-header,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card
  .e-card-header,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card
  .e-card-header {
  padding: 7px 11px 0;
}
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card
  .e-card-content,
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card
  .e-card-content,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card
  .e-card-content,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card
  .e-card-content {
  padding: 8px 11px;
}
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card
  .e-card-tags,
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card
  .e-card-footer,
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card
  .e-card-tags,
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card
  .e-card-footer,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card
  .e-card-tags,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card
  .e-card-footer,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card
  .e-card-tags,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card
  .e-card-footer {
  padding: 0 11px 7px;
}
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card.e-card-color,
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card.e-card-color,
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card,
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card.e-card-color,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card.e-card-color,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card {
  margin-bottom: 8px;
}
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card.e-card-color
  .e-card-header
  .e-card-header-title,
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card.e-card-color
  .e-card-header
  .e-card-header-title,
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card
  .e-card-header
  .e-card-header-title,
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card
  .e-card-header
  .e-card-header-title,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card.e-card-color
  .e-card-header
  .e-card-header-title,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card.e-card-color
  .e-card-header
  .e-card-header-title,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card
  .e-card-header
  .e-card-header-title,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card
  .e-card-header
  .e-card-header-title {
  font-size: 15px;
  line-height: 20px;
}
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card.e-card-color
  .e-card-content,
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card.e-card-color
  .e-card-content,
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card
  .e-card-content,
.e-bigger
  .e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card
  .e-card-content,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card.e-card-color
  .e-card-content,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card.e-card-color
  .e-card-content,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card
  .e-card-content,
.e-bigger.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container
  .e-card
  .e-card-content {
  font-size: 14px;
  line-height: 20px;
}

.e-mobile-popup-wrapper.e-device,
.e-mobile-popup-container.e-device {
  background: #fff;
  border-radius: 2px;
  bottom: 0;
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  display: flex;
  height: 60px;
  left: 0;
  margin: 0;
  max-width: 100%;
  min-width: 100%;
  opacity: 1;
  overflow: hidden;
  padding: 15px;
  position: fixed;
  right: 0;
  top: 0;
  user-select: none;
  width: 100%;
  z-index: 1004;
}
.e-mobile-popup-wrapper.e-device .e-popup-header .e-close,
.e-mobile-popup-container.e-device .e-popup-header .e-close {
  background: transparent;
}
.e-mobile-popup-wrapper.e-device .e-popup-content,
.e-mobile-popup-container.e-device .e-popup-content {
  color: inherit;
  font-size: 15px;
  font-weight: 500;
  overflow: hidden;
  padding: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.e-kanban-dialog.e-dialog {
  min-width: 350px;
}
.e-kanban-dialog.e-dialog .e-kanban-form-wrapper table,
.e-kanban-dialog.e-dialog .e-kanban-form-container table {
  width: 100%;
}
.e-kanban-dialog.e-dialog .e-kanban-form-wrapper table td,
.e-kanban-dialog.e-dialog .e-kanban-form-container table td {
  color: inherit;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  opacity: 0.87;
  padding: 5px 0;
  text-align: left;
}
.e-kanban-dialog.e-dialog .e-kanban-form-wrapper table td textarea.e-field,
.e-kanban-dialog.e-dialog .e-kanban-form-container table td textarea.e-field {
  resize: vertical;
}
.e-kanban-dialog.e-dialog .e-kanban-form-wrapper table .e-label,
.e-kanban-dialog.e-dialog .e-kanban-form-container table .e-label {
  padding-right: 10px;
  vertical-align: top;
  width: 70px;
}
.e-kanban-dialog.e-dialog .e-kanban-form-wrapper table .e-field,
.e-kanban-dialog.e-dialog .e-kanban-form-container table .e-field {
  width: 100%;
}
.e-kanban-dialog.e-dialog .e-footer-content .e-dialog-delete.e-btn {
  float: left;
  margin-left: 0;
}

*.e-rtl .e-kanban-form-wrapper table .e-label,
*.e-rtl .e-kanban-form-container table .e-label {
  padding-left: 10px;
  padding-right: 0;
  text-align: right;
}
*.e-rtl .e-dialog-delete {
  float: right;
}

.e-card-virtual-skeleton-wrapper .e-skeleton.e-skeleton-text.e-shimmer-wave {
  border-radius: 4px;
}

.e-card-virtual-skeleton-wrapper {
  height: inherit;
  overflow: hidden;
  padding: 8px 8px 0;
}

/*! kanban component theme */
.e-kanban {
  background: #fff;
}
.e-kanban .e-kanban-table .e-header-cells {
  background: #0079bf;
}
.e-kanban .e-kanban-table .e-header-cells .e-header-text {
  color: rgba(255, 255, 255, 1);
}
.e-kanban .e-kanban-table .e-header-cells .e-item-count {
  color: rgba(255, 255, 255, 0.87);
}
.e-kanban .e-kanban-table .e-header-cells .e-limits {
  color: inherit;
}
.e-kanban .e-kanban-table .e-header-cells .e-column-expand,
.e-kanban .e-kanban-table .e-header-cells .e-column-collapse {
  border: 1px solid transparent;
  color: rgba(0, 0, 0, 0.54);
}
.e-kanban .e-kanban-table .e-header-cells .e-column-expand:hover,
.e-kanban .e-kanban-table .e-header-cells .e-column-expand:focus,
.e-kanban .e-kanban-table .e-header-cells .e-column-collapse:hover,
.e-kanban .e-kanban-table .e-header-cells .e-column-collapse:focus {
  background: #e0e0e0;
  border: 1px solid transparent;
  border-radius: 100%;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.54);
}
.e-kanban
  .e-kanban-table.e-content-table
  .e-content-row:not(.e-swimlane-row)
  td {
  background: #f5f5f5;
}
.e-kanban
  .e-kanban-table.e-content-table
  .e-content-row.e-swimlane-row
  .e-swimlane-text,
.e-kanban
  .e-kanban-table.e-content-table
  .e-content-row.e-swimlane-row
  .e-item-count {
  color: rgba(0, 0, 0, 0.87);
}
.e-kanban
  .e-kanban-table.e-content-table
  .e-content-row
  .e-show-add-button:hover {
  background: rgba(95, 124, 138, 0.1);
}
.e-kanban
  .e-kanban-table.e-content-table
  .e-content-row
  .e-show-add-button
  .e-show-add-icon {
  color: rgb(95, 124, 138);
}
.e-kanban .e-kanban-table.e-content-table .e-card.e-selection {
  background: #eee;
}
.e-kanban .e-kanban-table.e-content-table .e-card .e-card-header {
  color: rgba(0, 0, 0, 0.87);
}
.e-kanban .e-kanban-table.e-content-table .e-card .e-card-content {
  color: rgba(0, 0, 0, 0.6);
}
.e-kanban .e-kanban-table.e-content-table .e-card .e-card-tag.e-card-label {
  background: #eceaff;
  color: #3225c9;
}
.e-kanban
  .e-kanban-table.e-content-table
  .e-target-multi-clone
  .e-column-key.e-multi-active {
  background: rgba(95, 124, 138, 0.1);
  border-color: rgb(95, 124, 138);
}
.e-kanban
  .e-kanban-table.e-content-table
  .e-target-multi-clone
  .e-column-key.e-multi-bottom-border {
  border-bottom-color: rgb(95, 124, 138);
}
.e-kanban
  .e-kanban-table.e-content-table
  .e-target-multi-clone
  .e-column-key.e-multi-active:first-child {
  border-color: rgb(95, 124, 138);
}
.e-kanban .e-frozen-swimlane-row {
  background: #fff;
}
.e-kanban .e-frozen-swimlane-row .e-swimlane-header .e-swimlane-text {
  color: rgba(0, 0, 0, 0.87);
}
.e-kanban .e-frozen-swimlane-row .e-swimlane-header .e-item-count {
  color: rgba(0, 0, 0, 0.87);
}

.e-kanban-dialog.e-dialog .e-kanban-form-wrapper table textarea {
  background: initial;
  color: rgba(0, 0, 0, 0.87);
  min-width: 70%;
}
.e-kanban-dialog.e-dialog .e-kanban-form-wrapper table textarea:focus {
  box-shadow: none;
}

.e-card-virtual-skeleton-wrapper,
.e-card-skeleton-wrapper {
  background: #f5f5f5;
}

.e-kanban .e-column-expand::before {
  content: "\e937";
}
.e-kanban .e-column-collapse::before {
  content: "\e956";
}
.e-kanban .e-swimlane-row-expand::before {
  content: "\e652";
}
.e-kanban .e-swimlane-row-collapse::before {
  content: "\e653";
}
.e-kanban .e-show-add-icon::before {
  content: "\e823";
}
.e-kanban .e-swimlane-header-toolbar .e-icon-menu::before {
  content: "\e99a";
}
.e-kanban.e-rtl .e-column-collapse::before {
  content: "\e937";
}
.e-kanban.e-rtl .e-column-expand::before {
  content: "\e956";
}
.e-kanban.e-rtl .e-swimlane-row-collapse::before {
  content: "\e654";
}

.e-mobile-popup-wrapper .e-close-icon::before {
  content: "\e7fc";
}

.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper
  .e-card {
  border: 1px solid #d3d3d3;
  box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.2);
}
